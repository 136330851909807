module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","linkImagesToOriginal":false,"markdownCaptions":true,"maxWidth":500,"quality":100,"showCaptions":true,"withWebp":true,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Build Up","short_name":"Build Up","start_url":"/","background_color":"#07506D","theme_color":"#07506D","display":"minimal-ui","icon":"src/images/book-stack.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6693890f52cf416e297fdeb61bdf8dab"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
